<script lang="ts">
	export let height = '48px' // Default height
	export let width = '48px' // Default width
	let className = ''
	export { className as class }
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<circle cx="24" cy="24" r="15" fill="white" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M41.76 17.744L45.436 20.7L45.432 20.706C46.4207 21.5002 46.9958 22.6999 46.9958 23.968C46.9958 25.2361 46.4207 26.4358 45.432 27.23L41.756 30.186C41.1733 30.6528 40.8711 31.3862 40.956 32.128L41.466 36.82C41.6048 38.0815 41.1632 39.3378 40.2656 40.235C39.3679 41.1323 38.1115 41.5733 36.85 41.434L32.16 40.924C31.4186 40.8418 30.6864 41.1434 30.218 41.724L27.262 45.4C26.4678 46.3887 25.2681 46.9638 24 46.9638C22.7319 46.9638 21.5321 46.3887 20.738 45.4L17.778 41.718C17.3098 41.1371 16.5775 40.8355 15.836 40.918L11.144 41.428C9.88247 41.5668 8.62618 41.1252 7.72896 40.2276C6.83174 39.3299 6.39068 38.0735 6.53 36.812L7.04 32.122C7.12407 31.3803 6.8221 30.6473 6.24 30.18L2.564 27.224C1.57531 26.4298 1.00015 25.2301 1.00015 23.962C1.00015 22.6939 1.57531 21.4942 2.564 20.7L6.244 17.742C6.82642 17.275 7.12846 16.5418 7.044 15.8L6.534 11.11C6.39523 9.84847 6.83683 8.59219 7.73444 7.69497C8.63205 6.79775 9.88853 6.35668 11.15 6.496L15.84 7C16.5818 7.08485 17.3152 6.78273 17.782 6.2L20.738 2.526C21.5574 1.57918 22.7478 1.03511 24 1.03511C25.2522 1.03511 26.4426 1.57918 27.262 2.526L30.218 6.2C30.6864 6.78055 31.4186 7.08215 32.16 7L36.856 6.496C38.1175 6.35723 39.3738 6.79884 40.271 7.69645C41.1683 8.59405 41.6093 9.85053 41.47 11.112L40.96 15.802C40.8775 16.5435 41.1791 17.2758 41.76 17.744ZM22.2224 31.4829C23.2403 31.4103 24.1785 30.9055 24.8 30.096L32.934 19.252C33.3627 18.6804 33.4539 17.9235 33.1732 17.2664C32.8925 16.6093 32.2826 16.1518 31.5732 16.0664C30.8638 15.9809 30.1627 16.2804 29.734 16.852L21.916 27.274L17.414 22.772C16.9118 22.2521 16.1682 22.0436 15.469 22.2266C14.7697 22.4096 14.2236 22.9557 14.0406 23.655C13.8576 24.3542 14.0661 25.0979 14.586 25.6L19.474 30.476C20.2041 31.189 21.2045 31.5555 22.2224 31.4829Z"
		fill="#2563EB"
	/>
</svg>
